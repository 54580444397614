img {
  object-fit: contain;
}
body a {
  text-decoration: none;
  background-color: "text.main";
}

body {
  height: 100vh;
  font-family: "Arial";
  color: white;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

.container {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100vh;
  display: grid;
  place-content: center;
}

.content {
  display: flex;
  gap: 5em;
  width: 100%;
  padding-top: 3em;
  position: relative;
}

.content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid white;
  transform: scaleX(1);
}

h1 {
  font-size: 4rem;
  width: 50vw;
  line-height: 97%;
  text-align: right;
}

section {
  height: 100vh;
  color: white;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}
#clip {
  background: var(
    --linear-1,
    linear-gradient(135deg, #001277 0%, #148e7e 76.87%, #19a97f 100%)
  );

  background-attachment: fixed;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;

  font-size: 28vw;
  font-weight: bold;
  text-align: center;
}

/* canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 0;
  background: rgb(238, 174, 203);
  background: -moz-radial-gradient(
    circle,
    rgba(238, 174, 203, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(238, 174, 203, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(238, 174, 203, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eeaecb",endColorstr="#ffffff",GradientType=1);
} */
